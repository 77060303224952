import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import UploadSection from './components/UploadSection';
import AnimationPage from './components/AnimationPage';
import ClaimDetailsPage from './components/ClaimDetailsPage';
import AuditDetailsPage from './components/AuditDetailsPage';
import TariffDetails from './components/TariffDetails';
import DummyAnimationPage from './components/DummyAnimationPage';
import SettlementLetter from './components/SettlementLetter';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'typeface-manrope';
import JobPipeline from './components/JobPipeline';
import DashboardIcon from './icons/dashboard-icon.svg';
import TpaIcon from './icons/tpa-icon.svg';
import AuditClaimIcon from './icons/audit-claim-icon.svg';
import AiAssistantIcon from './icons/ai-assistant-icon.svg';
import ChevronDownIcon from './icons/chevron-down-icon.svg';
import { API_ENDPOINTS } from './config';
function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <Routes>
          <Route path="/" element={<UploadPage />} />
          <Route path="/animation" element={<AnimationPage />} />
          <Route path="/claim-details" element={<ClaimDetailsPage />} />
          <Route path="/document/:docId" element={<AuditDetailsPage />} />
          <Route path="/settlement/:documentId" element={<TariffDetails />} />
          <Route path="/dummy" element={<DummyAnimationPage />} />
          <Route path="/pipe" element={<JobPipeline />} />
          <Route
          path="/settlement-letter"
          element={
            <SettlementLetter
              recipientName="John Doe"
              recipientAddress="123 Main St, Anytown, USA"
              claimReferenceNumber="XYZ123456789"
            />              
            }
          />
        </Routes>
      </div>
    </Router>
  );
}


function Sidebar() {
  const navigate = useNavigate();
  const [isAuditDropdownOpen, setIsAuditDropdownOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    setIsAuditDropdownOpen(false); // Close the dropdown after navigation
  };

  const toggleAuditDropdown = () => {
    setIsAuditDropdownOpen(!isAuditDropdownOpen);
  };

  return (
    <div className="sidebar" onMouseLeave={() => setIsAuditDropdownOpen(false)}>
      <div className="sidebar-logo">
        <img src="/logonew.svg" alt="Logo" />
      </div>
      <ul className="sidebar-menu">
        <li onClick={() => handleNavigation('/')}>
          <img src={DashboardIcon} alt="Dashboard" />
          <span>Dashboard</span>
        </li>
        <li onClick={() => handleNavigation('/tpas')}>
          <img src={TpaIcon} alt="TPAs" />
          <span>TPAs</span>
        </li>
        <li className="menu-item" onMouseEnter={() => setIsAuditDropdownOpen(true)}>
          <div>
            <img src={AuditClaimIcon} alt="Audit Claim" />
            <span>Audit Claim</span>
          </div>
          {isAuditDropdownOpen && (
            <ul className="submenu">
              <li onClick={() => handleNavigation('/claim-details')}>Individual</li>
              <li onClick={() => handleNavigation('/bulk')}>Bulk</li>
            </ul>
          )}
        </li>
        <li onClick={() => handleNavigation('/ai-assistant')}>
          <img className='ai' src={AiAssistantIcon} alt="AI Assistant" />
          <span>AI Assistant</span>
        </li>
      </ul>
    </div>
  );
}
function UploadPage() {
  const [patientId, setPatientId] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Log the API URLs being used in the console
    console.log("Python Service URL:", API_ENDPOINTS.PYTHON_SERVICE);
    console.log("Express Service URL:", API_ENDPOINTS.EXPRESS_SERVICE);
  }, []);
  

  const handleFileUpload = async (title, files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('title', title);

    if (title.toLowerCase() === 'settlement letter') {
      try {
        const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/upload`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          setPatientId(data.claimId);

          // Move previously uploaded files to the correct folder
          for (const [title, files] of Object.entries(uploadedFiles)) {
            moveFilesToPatientFolder(data.claimId, title, files);
          }
          setUploadedFiles({});
        } else {
          console.error(data.msg);
        }
      } catch (error) {
        console.error('Upload error', error);
      }
    } else {
      // Save files to temporary storage
      setUploadedFiles(prev => ({
        ...prev,
        [title]: [...(prev[title] || []), ...files]
      }));

      // Save to temporary folder
      await saveFilesToTemporaryFolder(title, files);
    }
  };

  const saveFilesToTemporaryFolder = async (title, files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('title', title);

    try {
      const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/upload-temp`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        console.error('Failed to save files to temporary folder');
      }
    } catch (error) {
      console.error('Save to temporary folder error', error);
    }
  };

  const moveFilesToPatientFolder = async (patientId, title, files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('patientId', patientId);
    formData.append('title', title);

    try {
      const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/move-files`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        console.error('Failed to move files');
      }
    } catch (error) {
      console.error('Move files error', error);
    }
  };

  const handleNextClick = () => {
    if (!patientId) {
      alert('Please upload the settlement letter first.');
      return;
    }
    navigate('/animation', { state: { patientId } });
  };

  return (
    <div className="main-content">
      <header className="upload-page-header">
        <h1>Welcome, Raj</h1>
        <p>To audit an individual claim, upload the framework documents</p>
      </header>
      <div className="upload-sections">
        
        <UploadSection title="Claim Document" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />
        <UploadSection title="Policy Wording Document" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />
        <UploadSection title="Policy Document" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />
        <UploadSection title="STG Document" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />
        <UploadSection title="Tariff Document" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />

        <UploadSection title="Settlement letter" formats="PNG, JPG, PDF" onFileUpload={handleFileUpload} />
      </div>
      <button className="next-button" onClick={handleNextClick} disabled={!patientId}>
        Next
      </button>
    </div>
  );
}

export default App;
