// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`; // Updated to the fixed PDF API URL
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`; // Updated to the other PDF API URL

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data'; // Update to your FastAPI server URL
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   function toTitleCase(str) {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\w\S*/g, function(txt) {
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       });
//   }

//   if (!responseText || !pdfData || !fixedPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header docId={docId} />
//       <div className="content">
//         {/* <div className="response-container">
//           {responseText.map((text, index) => (
//             <div key={index} className="response-item">
//               <p><strong>Category:</strong> {titleCaseDocId}</p>
//               <p><strong>Details:</strong></p>
//               <div dangerouslySetInnerHTML={{ __html: marked(text) }} />
//             </div>
//           ))}
//         </div> */}
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Fixed Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{titleCaseDocId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>

//       <div className="button-group">
//           <button className="secondary-button">Overrule Discrepancy</button>
//           <button>Confirm Discrepancy</button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;









//testing

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36); // Initial Est. Eligible amount
//   const [discrepancyAmount, setDiscrepancyAmount] = useState(0); // Store the discrepancy amount
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({}); // Track overruled discrepancies by index
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0); // Track the current discrepancy index

//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   const handleOverruleDiscrepancy = () => {
//     // Check if this discrepancy has already been overruled
//     if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//       setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmount);
//       setOverruledDiscrepancies(prevState => ({
//         ...prevState,
//         [currentDiscrepancyIndex]: true // Mark this discrepancy as overruled
//       }));
//     }
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     setDiscrepancyAmount(newDiscrepancyAmount);
//     setCurrentDiscrepancyIndex(discrepancyIndex); // Update the current discrepancy index
//   };

//   function toTitleCase(str) {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\w\S*/g, function(txt) {
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       });
//   }

//   if (!responseText || !pdfData || !fixedPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header 
//         docId={docId} 
//         estEligibleAmount={estEligibleAmount}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex} // Pass the current index to the Header
//       />
//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Fixed Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{titleCaseDocId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>

//         <div className="button-group">
//           <button 
//             className="secondary-button" 
//             onClick={handleOverruleDiscrepancy}
//             disabled={overruledDiscrepancies[currentDiscrepancyIndex]} // Disable if already overruled
//           >
//             Overrule Discrepancy
//           </button>
//           <button>Confirm Discrepancy</button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;








// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36); // Initial Est. Eligible amount
//   const [discrepancyAmount, setDiscrepancyAmount] = useState(0); // Store the discrepancy amount
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({}); // Track overruled discrepancies by index
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0); // Track the current discrepancy index
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false); // State for confirmation box
//   const [actionToConfirm, setActionToConfirm] = useState(null); // State to track which action needs confirmation
//   const [actionHistory, setActionHistory] = useState([]); // Track action history for revert functionality

//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   const handleOverruleDiscrepancy = () => {
//     // Check if the current discrepancy has already been overruled
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return; // If already overruled, do nothing
//     }
//     // Set the action that needs confirmation
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       // Apply the overrule discrepancy logic
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         // Update the estimated eligible amount by adding the discrepancy amount
//         setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmount);

//         // Mark this discrepancy as overruled
//         setOverruledDiscrepancies(prevState => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true
//         }));

//         // Optionally, track this action for the ability to revert it later
//         setActionHistory(prevHistory => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmount }
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null); // Reset the action to confirm
//   };

//   const revertLastAction = () => {
//     if (actionHistory.length === 0) return;

//     const lastAction = actionHistory[actionHistory.length - 1];
//     if (lastAction.action === 'overrule') {
//       setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies(prevState => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     }

//     setActionHistory(prevHistory => prevHistory.slice(0, -1));
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     setDiscrepancyAmount(newDiscrepancyAmount);
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   function toTitleCase(str) {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\w\S*/g, function(txt) {
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       });
//   }

//   if (!responseText || !pdfData || !fixedPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header 
//         docId={docId} 
//         estEligibleAmount={estEligibleAmount}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//       />
//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Fixed Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{titleCaseDocId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>

//         <div className="button-group">
//           <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//             Overrule Discrepancy
//           </button>
//           <div className="confirm-discrepancy-btn">
//             <button onClick={handleConfirmDiscrepancy}>
//               Confirm Discrepancy
//             </button>
//             {isConfirmBoxVisible && (
//               <div className="floating-confirm-box">
//                 <p>Are you sure you want to confirm this discrepancy?</p>
//               </div>
//             )}
//           </div>
//           <button onClick={revertLastAction}>Revert Last Action</button>
//         </div>

//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;




// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36); // Initial Est. Eligible amount
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({}); // Store all discrepancy amounts
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({}); // Track overruled discrepancies by index
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0); // Track the current discrepancy index
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false); // State for confirmation box
//   const [actionToConfirm, setActionToConfirm] = useState(null); // State to track which action needs confirmation
//   const [actionHistory, setActionHistory] = useState([]); // Track action history for revert functionality

//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   const handleOverruleDiscrepancy = () => {
//     // Check if the current discrepancy has already been overruled
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return; // If already overruled, do nothing
//     }
//     // Set the action that needs confirmation
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       // Apply the overrule discrepancy logic
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         // Update the estimated eligible amount by adding the discrepancy amount
//         setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);

//         // Mark this discrepancy as overruled
//         setOverruledDiscrepancies(prevState => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true
//         }));

//         // Optionally, track this action for the ability to revert it later
//         setActionHistory(prevHistory => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] }
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null); // Reset the action to confirm
//   };

//   const revertLastAction = () => {
//     if (actionHistory.length === 0) return;

//     const lastAction = actionHistory[actionHistory.length - 1];
//     if (lastAction.action === 'overrule') {
//       setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies(prevState => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     }

//     setActionHistory(prevHistory => prevHistory.slice(0, -1));
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     setDiscrepancyAmounts(prevState => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   // function toTitleCase(str) {
//   //   return str
//   //     .replace(/_/g, ' ')
//   //     .replace(/\w\S*/g, function(txt) {
//   //       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   //     });
//   // }

//   const toTitleCase = (str) => {
//     if (!str) return '';  // Null/undefined check
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };
//   if (!responseText || !pdfData || !fixedPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header 
//         docId={docId} 
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0} // Pass the current discrepancy amount to Header
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange} // Passing the function here
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//       />
//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Fixed Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{titleCaseDocId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>

//         <div className="button-group">
//           <div className="revert-button-container">
//             <button onClick={revertLastAction} className="revert-button">Revert Last Action</button>
//           </div>
//           <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//             Overrule Discrepancy
//           </button>
//           <div className="confirm-discrepancy-btn">
//             <button onClick={handleConfirmDiscrepancy}>
//               Confirm Discrepancy
//             </button>
//             {isConfirmBoxVisible && (
//               <div className="floating-confirm-box">
//                 <p>Are you sure you want to confirm this discrepancy?</p>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;





// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36); // Initial Est. Eligible amount
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({}); // Store all discrepancy amounts
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({}); // Track overruled discrepancies by index
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0); // Track the current discrepancy index
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false); // State for confirmation box
//   const [actionToConfirm, setActionToConfirm] = useState(null); // State to track which action needs confirmation
//   const [actionHistory, setActionHistory] = useState([]); // Track action history for revert functionality

//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data:', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   const handleOverruleDiscrepancy = () => {
//     // Check if the current discrepancy has already been overruled
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return; // If already overruled, do nothing
//     }
//     // Set the action that needs confirmation
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       // Apply the overrule discrepancy logic
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         // Update the estimated eligible amount by adding the discrepancy amount
//         setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);

//         // Mark this discrepancy as overruled
//         setOverruledDiscrepancies(prevState => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true
//         }));

//         // Optionally, track this action for the ability to revert it later
//         setActionHistory(prevHistory => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] }
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null); // Reset the action to confirm
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     // Save the current amount in action history before updating
//     setActionHistory(prevHistory => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 }
//     ]);

//     // Update the discrepancy amounts
//     setDiscrepancyAmounts(prevState => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount
//     }));

//     setCurrentDiscrepancyIndex(discrepancyIndex);
// };

// const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//         console.log('No actions to revert');
//         return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       // Revert overrule action
//       setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies(prevState => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     } else if (lastAction.action === 'update') {
//       // Revert discrepancy update action
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       // Update the backend with the reverted amount
//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [discrepancyKey]: revertedAmount
//           })
//         });

//         if (response.ok) {
//           console.log("Discrepancy reverted successfully on the server.");
          
//           // Update the local state
//           setDiscrepancyAmounts(prevState => ({
//             ...prevState,
//             [lastAction.index]: revertedAmount
//           }));
          
//           setCurrentDiscrepancyIndex(lastAction.index);
//         } else {
//           console.error("Failed to revert discrepancy on the server.");
//         }
//       } catch (error) {
//         console.error("Error reverting discrepancy:", error);
//       }
//     }

//     // Remove the last action from history
//     setActionHistory(prevHistory => prevHistory.slice(0, -1));
// };


//   const toTitleCase = (str) => {
//     if (!str) return '';  // Null/undefined check
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };
  
//   if (!responseText || !pdfData || !fixedPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header 
//         docId={docId} 
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0} // Pass the current discrepancy amount to Header
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange} // Passing the function here
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//       />

//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">Revert Last Action</button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Discrepancy
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>
//             Confirm Discrepancy
//           </button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{titleCaseDocId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;




// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [fixedPdfData, setFixedPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null); // State for Claim Document PDF
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36); // Initial Est. Eligible amount
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({}); // Store all discrepancy amounts
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({}); // Track overruled discrepancies by index
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0); // Track the current discrepancy index
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false); // State for confirmation box
//   const [actionToConfirm, setActionToConfirm] = useState(null); // State to track which action needs confirmation
//   const [actionHistory, setActionHistory] = useState([]); // Track action history for revert functionality
//   const [isClaimDocument, setIsClaimDocument] = useState(false); // State for toggling between documents

//   const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//   const claimPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`; // Example URL for Claim Document PDF

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     const fetchFixedPdfData = async () => {
//       try {
//         const response = await fetch(fixedPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setFixedPdfData(url);
//         } else {
//           console.error('Failed to fetch fixed PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching fixed PDF data:', error);
//       }
//     };

//     const fetchClaimPdfData = async () => {
//       try {
//         const response = await fetch(claimPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setClaimPdfData(url);
//         } else {
//           console.error('Failed to fetch Claim PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching Claim PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//     fetchFixedPdfData();
//     fetchClaimPdfData();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   const handleToggleDocument = () => {
//     setIsClaimDocument(prevState => !prevState); // Toggle between the documents
//   };

//   const handleOverruleDiscrepancy = () => {
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return; // If already overruled, do nothing
//     }
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);
//         setOverruledDiscrepancies(prevState => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true
//         }));
//         setActionHistory(prevHistory => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] }
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     setActionHistory(prevHistory => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 }
//     ]);
//     setDiscrepancyAmounts(prevState => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//         console.log('No actions to revert');
//         return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies(prevState => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [discrepancyKey]: revertedAmount
//           })
//         });

//         if (response.ok) {
//           console.log("Discrepancy reverted successfully on the server.");
//           setDiscrepancyAmounts(prevState => ({
//             ...prevState,
//             [lastAction.index]: revertedAmount
//           }));
//           setCurrentDiscrepancyIndex(lastAction.index);
//         } else {
//           console.error("Failed to revert discrepancy on the server.");
//         }
//       } catch (error) {
//         console.error("Error reverting discrepancy:", error);
//       }
//     }
//     setActionHistory(prevHistory => prevHistory.slice(0, -1));
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//   };
  
//   const toTitleCase = (str) => {
//     if (!str) return ''; 
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };
  

//   if (!responseText || !pdfData || !fixedPdfData || !claimPdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   return (
//     <div className="audit-details-page">
//       <Header 
//         docId={docId} 
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0} 
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//       />

//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">Revert Last Action</button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Discrepancy
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>
//             Confirm Discrepancy
//           </button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={fixedPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <div className="toggle-container">
//               <div className="toggle-button" onClick={handleToggleDocument}>
//                 <div className={`toggle-option ${!isClaimDocument ? 'active' : 'inactive'}`}>
//                 {titleCaseDocId.length > 18 ? `${titleCaseDocId.substring(0, 18)}..` : titleCaseDocId}
//                 </div>
//                 <div className={`toggle-option ${isClaimDocument ? 'active' : 'inactive'}`}>
//                   Settlement Document
//                 </div>
//               </div>
//             </div>
//             <div className="pdf-viewer">
//               <Document
//                 file={isClaimDocument ? claimPdfData : pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;



// Testing code above is working one

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [settlementPdfData, setSettlementPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [isDocumentType, setIsDocumentType] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);

//   useEffect(() => {
//     console.log('Starting useEffect, docId:', docId);

//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         console.log('Fetching response text from:', url);
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Received response text:', data);

//           if (data.response_text && data.response_text[docId]) {
//             setResponseText(data.response_text[docId]);
//           } else {
//             console.warn(`No response text found for docId: ${docId}`);
//             setResponseText('No response text available.');
//           }
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch response text:', errorText);
//           setError('Failed to fetch response text');
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//         setError('Error fetching response text');
//       }
//     };

//     const fetchDocuments = async () => {
//       try {
//         if (docId === 'nonpayables') {
//           console.log('Fetching nonpayables documents...');
//           const response = await fetch('http://localhost:5000/get_documents', {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log('Received nonpayables document data:', data);

//             const baseUrl = 'http://localhost:5000/';
//             const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
//             const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

//             console.log('Constructed Claim PDF URL:', claimPdfUrl);
//             console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

//             const claimBlob = await fetch(claimPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//             });
//             const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//             });

//             const claimBlobUrl = URL.createObjectURL(claimBlob);
//             const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);

//             console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
//             console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

//             setClaimPdfData(claimBlobUrl);
//             setPdfData(nonpayablesBlobUrl);

//             // Fetch settlement PDF for nonpayables
//             const settlementPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`;
//             console.log('Fetching settlement PDF from:', settlementPdfUrl);
//             const settlementPdfResponse = await fetch(settlementPdfUrl);
//             if (settlementPdfResponse.ok) {
//               const settlementPdfBlob = await settlementPdfResponse.blob();
//               setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//               console.log('Successfully loaded settlement PDF for nonpayables');
//             } else {
//               console.warn('Settlement PDF not found or failed to load for nonpayables');
//               setSettlementPdfData(null); // Explicitly set to null if not found
//             }
//           } else {
//             const errorText = await response.text();
//             console.error('Failed to fetch documents:', errorText);
//             setError('Failed to fetch documents');
//           }
//         } else {
//           console.log('Fetching other document types...');
//           const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const settlementPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`;

//           console.log('Fetching fixed PDF from:', fixedPdfUrl);
//           const fixedPdfResponse = await fetch(fixedPdfUrl);
//           if (fixedPdfResponse.ok) {
//             const fixedPdfBlob = await fixedPdfResponse.blob();
//             setClaimPdfData(URL.createObjectURL(fixedPdfBlob)); // Use Claim PDF state for fixedPdfData
//           } else {
//             throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//           }

//           console.log('Fetching other PDF from:', otherPdfUrl);
//           const otherPdfResponse = await fetch(otherPdfUrl);
//           if (otherPdfResponse.ok) {
//             const otherPdfBlob = await otherPdfResponse.blob();
//             setPdfData(URL.createObjectURL(otherPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//           }

//           console.log('Fetching settlement PDF from:', settlementPdfUrl);
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
//           if (settlementPdfResponse.ok) {
//             const settlementPdfBlob = await settlementPdfResponse.blob();
//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             console.warn('Settlement PDF not found or failed to load');
//             setSettlementPdfData(null); // Explicitly set to null if not found
//           }
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching documents:', error);
//         setError('Error fetching documents');
//         setLoading(false);
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     console.log('Loaded left document with number of pages:', numPages);
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     console.log('Loaded right document with number of pages:', numPages);
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   const handleToggleDocument = () => {
//     setIsDocumentType((prevState) => !prevState);
//   };

//   const handleOverruleDiscrepancy = () => {
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return;
//     }
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         setEstEligibleAmount((prevAmount) => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);
//         setOverruledDiscrepancies((prevState) => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true,
//         }));
//         setActionHistory((prevHistory) => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] },
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     setActionHistory((prevHistory) => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
//     ]);
//     setDiscrepancyAmounts((prevState) => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount,
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//       console.log('No actions to revert');
//       return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       setEstEligibleAmount((prevAmount) => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies((prevState) => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [discrepancyKey]: revertedAmount,
//           }),
//         });

//         if (response.ok) {
//           console.log('Discrepancy reverted successfully on the server.');
//           setDiscrepancyAmounts((prevState) => ({
//             ...prevState,
//             [lastAction.index]: revertedAmount,
//           }));
//           setCurrentDiscrepancyIndex(lastAction.index);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to revert discrepancy on the server:', errorText);
//         }
//       } catch (error) {
//         console.error('Error reverting discrepancy:', error);
//       }
//     }
//     setActionHistory((prevHistory) => prevHistory.slice(0, -1));
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//     nonpayables: 'Nonpayables',
//     discrepancy_summary: 'Discrepancy Summary'

//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (pdfError) {
//     console.error('PDF Error encountered:', pdfError);
//     return <div>Error: {pdfError}</div>;
//   }

//   if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
//     console.log(
//       'Missing data - responseText:',
//       responseText,
//       'pdfData:',
//       pdfData,
//       'claimPdfData:',
//       claimPdfData,
//       'settlementPdfData:',
//       settlementPdfData
//     );
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);

//   return (
//     <div className="audit-details-page">
//       <Header
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//       />

//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">
//             Revert Last Action
//           </button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Discrepancy
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>Confirm Discrepancy</button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//           <h3>Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document file={claimPdfData} onLoadSuccess={onDocumentLoadSuccessLeft} onLoadError={onDocumentLoadError}>
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <div className="toggle-container">
//               <div className="toggle-button" onClick={handleToggleDocument}>
//                 <div className={`toggle-option ${isDocumentType ? 'active' : 'inactive'}`}>
//                 {titleCaseDocId.length > 18 ? `${titleCaseDocId.substring(0, 18)}..` : titleCaseDocId}
//                 </div>
//                 <div className={`toggle-option ${!isDocumentType ? 'active' : 'inactive'}`}>Settlement Document</div>
//               </div>
//             </div>
//             <div className="pdf-viewer">
//               <Document
//                 file={isDocumentType ? pdfData : settlementPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//                 onLoadError={onDocumentLoadError}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;








// testing the final

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [settlementPdfData, setSettlementPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [isDocumentType, setIsDocumentType] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0); // New state for ICE Authorized Amount

//   // Define the missing state variables
//   const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
//   const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
//   const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

//   useEffect(() => {
//     console.log('Starting useEffect, docId:', docId);

//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         console.log('Fetching response text from:', url);
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Received response text:', data);

//           if (data.response_text && data.response_text[docId]) {
//             setResponseText(data.response_text[docId]);
//           } else {
//             console.warn(`No response text found for docId: ${docId}`);
//             setResponseText('No response text available.');
//           }
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch response text:', errorText);
//           setError('Failed to fetch response text');
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//         setError('Error fetching response text');
//       }
//     };

//     const fetchDocuments = async () => {
//       try {
//         if (docId === 'nonpayables') {
//           console.log('Fetching nonpayables documents...');
//           const response = await fetch('http://localhost:5000/get_documents', {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log('Received nonpayables document data:', data);

//             const baseUrl = 'http://localhost:5000/';
//             const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
//             const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

//             console.log('Constructed Claim PDF URL:', claimPdfUrl);
//             console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

//             const claimBlob = await fetch(claimPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//             });
//             const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//             });

//             const claimBlobUrl = URL.createObjectURL(claimBlob);
//             const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);

//             console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
//             console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

//             setClaimPdfData(claimBlobUrl);
//             setPdfData(nonpayablesBlobUrl);

//             // Fetch settlement PDF for nonpayables
//             const settlementPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`;
//             console.log('Fetching settlement PDF from:', settlementPdfUrl);
//             const settlementPdfResponse = await fetch(settlementPdfUrl);
//             if (settlementPdfResponse.ok) {
//               const settlementPdfBlob = await settlementPdfResponse.blob();
//               setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//               console.log('Successfully loaded settlement PDF for nonpayables');
//             } else {
//               console.warn('Settlement PDF not found or failed to load for nonpayables');
//               setSettlementPdfData(null); // Explicitly set to null if not found
//             }
//           } else {
//             const errorText = await response.text();
//             console.error('Failed to fetch documents:', errorText);
//             setError('Failed to fetch documents');
//           }
//         } else {
//           console.log('Fetching other document types...');
//           const fixedPdfUrl = `http://localhost:5000/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const settlementPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`;

//           console.log('Fetching fixed PDF from:', fixedPdfUrl);
//           const fixedPdfResponse = await fetch(fixedPdfUrl);
//           if (fixedPdfResponse.ok) {
//             const fixedPdfBlob = await fixedPdfResponse.blob();
//             setClaimPdfData(URL.createObjectURL(fixedPdfBlob)); // Use Claim PDF state for fixedPdfData
//           } else {
//             throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//           }

//           console.log('Fetching other PDF from:', otherPdfUrl);
//           const otherPdfResponse = await fetch(otherPdfUrl);
//           if (otherPdfResponse.ok) {
//             const otherPdfBlob = await otherPdfResponse.blob();
//             setPdfData(URL.createObjectURL(otherPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//           }

//           console.log('Fetching settlement PDF from:', settlementPdfUrl);
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
//           if (settlementPdfResponse.ok) {
//             const settlementPdfBlob = await settlementPdfResponse.blob();
//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             console.warn('Settlement PDF not found or failed to load');
//             setSettlementPdfData(null); // Explicitly set to null if not found
//           }
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching documents:', error);
//         setError('Error fetching documents');
//         setLoading(false);
//       }
//     };

//     const fetchPolicyStatus = async () => {
//       const url = 'http://localhost:5000/policy-status';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
//           setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
//           setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
//           setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
//           setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
//           console.log('Policy status fetched:', data);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch policy status:', errorText);
//           setError('Failed to fetch policy status');
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//         setError('Error fetching policy status');
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//     fetchPolicyStatus();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     console.log('Loaded left document with number of pages:', numPages);
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     console.log('Loaded right document with number of pages:', numPages);
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   const handleToggleDocument = () => {
//     setIsDocumentType((prevState) => !prevState);
//   };

//   const handleConfirmDiscrepancy = async () => {
//     if (actionToConfirm === 'overrule') {
//         if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//             const discrepancyAmount = discrepancyAmounts[currentDiscrepancyIndex] || 0;
//             const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;

//             let requestBody = {
//                 ice_authorized_amount: newIceAuthorizedAmount
//             };

//             // **Calculate new totals before updating the state and request body**
//             let newTotalPolicyWordingAmount;
//             let newTotalNonpayablesAmount;
//             let newTotalTariffDiscrepancyAmount;

//             if (docId === 'policy_wording_document') {
//                 newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount - discrepancyAmount;
//                 requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//             } else if (docId === 'nonpayables') {
//                 newTotalNonpayablesAmount = totalNonpayablesAmount - discrepancyAmount;
//                 requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//             } else if (docId === 'tariff_document') {
//                 newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount - discrepancyAmount;
//                 requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//             }

//             try {
//                 // Call the FastAPI endpoint to update the data
//                 const response = await fetch('http://localhost:5000/update-data/', {
//                     method: 'PUT',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(requestBody),
//                 });

//                 if (response.ok) {
//                     const data = await response.json();
//                     console.log(data.message);

//                     // Update state only after successful response from the server
//                     setIceAuthorizedAmount(newIceAuthorizedAmount);

//                     if (docId === 'policy_wording_document') {
//                         setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//                     } else if (docId === 'nonpayables') {
//                         setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//                     } else if (docId === 'tariff_document') {
//                         setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//                     }

//                     setOverruledDiscrepancies((prevState) => ({
//                         ...prevState,
//                         [currentDiscrepancyIndex]: true,
//                     }));

//                     setActionHistory((prevHistory) => [
//                         ...prevHistory,
//                         { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmount },
//                     ]);
//                 } else {
//                     console.error('Failed to update data:', await response.text());
//                 }
//             } catch (error) {
//                 console.error('Error updating data:', error);
//             }
//         }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
// };

// const handleOverruleDiscrepancy = () => {
//   setActionToConfirm('overrule');
//   setIsConfirmBoxVisible(true);
// };


//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     setActionHistory((prevHistory) => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
//     ]);
//     setDiscrepancyAmounts((prevState) => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount,
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//         console.log('No actions to revert');
//         return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//         const revertedAmount = iceAuthorizedAmount - lastAction.amount;
//         let requestBody = {
//             ice_authorized_amount: revertedAmount
//         };

//         // Declare variables before updating state and request body
//         let newTotalPolicyWordingAmount;
//         let newTotalNonpayablesAmount;
//         let newTotalTariffDiscrepancyAmount;

//         // Revert the specific document amount based on docId
//         if (docId === 'policy_wording_document') {
//             newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount + lastAction.amount;
//             requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//         } else if (docId === 'nonpayables') {
//             newTotalNonpayablesAmount = totalNonpayablesAmount + lastAction.amount;
//             requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//         } else if (docId === 'tariff_document') {
//             newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount + lastAction.amount;
//             requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//         }

//         try {
//             // Call the FastAPI endpoint to update the data on the server
//             const response = await fetch('http://localhost:5000/update-data/', {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestBody),
//             });

//             if (response.ok) {
//                 const data = await response.json();
//                 console.log(data.message);

//                 // Update state after successful response from the server
//                 setIceAuthorizedAmount(revertedAmount);

//                 if (docId === 'policy_wording_document') {
//                     setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//                 } else if (docId === 'nonpayables') {
//                     setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//                 } else if (docId === 'tariff_document') {
//                     setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//                 }

//                 // Remove the overruled discrepancy from state
//                 setOverruledDiscrepancies((prevState) => {
//                     const newState = { ...prevState };
//                     delete newState[lastAction.index];
//                     return newState;
//                 });
//             } else {
//                 console.error('Failed to update data:', await response.text());
//             }
//         } catch (error) {
//             console.error('Error updating data:', error);
//         }
//     } else if (lastAction.action === 'update') {
//         const revertedAmount = lastAction.amount;
//         const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//         try {
//             const response = await fetch('http://localhost:5000/update-discrepancy', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     [discrepancyKey]: revertedAmount,
//                 }),
//             });

//             if (response.ok) {
//                 console.log('Discrepancy reverted successfully on the server.');
//                 setDiscrepancyAmounts((prevState) => ({
//                     ...prevState,
//                     [lastAction.index]: revertedAmount,
//                 }));
//                 setCurrentDiscrepancyIndex(lastAction.index);
//             } else {
//                 const errorText = await response.text();
//                 console.error('Failed to revert discrepancy on the server:', errorText);
//             }
//         } catch (error) {
//             console.error('Error reverting discrepancy:', error);
//         }
//     }

//     // Remove the last action from the history
//     setActionHistory((prevHistory) => prevHistory.slice(0, -1));
// };


//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//     nonpayables: 'Nonpayables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (pdfError) {
//     console.error('PDF Error encountered:', pdfError);
//     return <div>Error: {pdfError}</div>;
//   }

//   if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
//     console.log(
//       'Missing data - responseText:',
//       responseText,
//       'pdfData:',
//       pdfData,
//       'claimPdfData:',
//       claimPdfData,
//       'settlementPdfData:',
//       settlementPdfData
//     );
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocId = toTitleCase(docId);

//   console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);

//   return (
//     <div className="audit-details-page">
//       <Header
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//         iceAuthorizedAmount={iceAuthorizedAmount} // Pass ICE Authorized Amount to Header
//       />

// <div className="button-group">
//     <div className="revert-button-container">
//         <button onClick={revertLastAction} className="revert-button">
//             Revert Last Action
//         </button>
//     </div>
//     <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//         Overrule Discrepancy
//     </button>
//     <div className="confirm-discrepancy-btn">
//         <button onClick={handleConfirmDiscrepancy}>Confirm Discrepancy</button>
//         {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//                 <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//         )}
//     </div>
// </div>


//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document file={claimPdfData} onLoadSuccess={onDocumentLoadSuccessLeft} onLoadError={onDocumentLoadError}>
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <div className="toggle-container">
//               <div className="toggle-button" onClick={handleToggleDocument}>
//                 <div className={`toggle-option ${isDocumentType ? 'active' : 'inactive'}`}>
//                   {titleCaseDocId.length > 18 ? `${titleCaseDocId.substring(0, 18)}..` : titleCaseDocId}
//                 </div>
//                 <div className={`toggle-option ${!isDocumentType ? 'active' : 'inactive'}`}>Settlement Document</div>
//               </div>
//             </div>
//             <div className="pdf-viewer">
//               <Document
//                 file={isDocumentType ? pdfData : settlementPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//                 onLoadError={onDocumentLoadError}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;










// testing for the settlement page


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import './AuditDetailsPage.css';
import { Document, Page, pdfjs } from 'react-pdf';
import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
import { marked } from 'marked';
import { API_ENDPOINTS } from '../config';
// Set the workerSrc property to use the locally hosted worker script
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

function AuditDetailsPage() {
  const { docId } = useParams();
  const [responseText, setResponseText] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [claimPdfData, setClaimPdfData] = useState(null);
  const [settlementPdfData, setSettlementPdfData] = useState(null);
  const [numPagesLeft, setNumPagesLeft] = useState(null);
  const [numPagesRight, setNumPagesRight] = useState(null);
  const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
  const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
  const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
  const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
  const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [actionHistory, setActionHistory] = useState([]);
  const [isDocumentType, setIsDocumentType] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0); // New state for ICE Authorized Amount
  const [iceSettlementPdfData, setIceSettlementPdfData] = useState(null);

  // Define the missing state variables
  const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
  const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
  const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

  useEffect(() => {
    console.log('Starting useEffect, docId:', docId);

    const fetchResponseText = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
      try {
        console.log('Fetching response text from:', url);
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Received response text:', data);

          if (data.response_text && data.response_text[docId]) {
            setResponseText(data.response_text[docId]);
          } else {
            console.warn(`No response text found for docId: ${docId}`);
            setResponseText('No response text available.');
          }
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch response text:', errorText);
          setError('Failed to fetch response text');
        }
      } catch (error) {
        console.error('Error fetching response text:', error);
        setError('Error fetching response text');
      }
    };

    const fetchDocuments = async () => {
      try {
        if (docId === 'discrepancy_summary') {
          console.log('Fetching discrepancy summary documents...');
          const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;
          const iceSettlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/ice_settlement?cb=${new Date().getTime()}`;

          const [settlementPdfResponse, iceSettlementPdfResponse] = await Promise.all([
            fetch(settlementPdfUrl),
            fetch(iceSettlementPdfUrl)
          ]);

          if (settlementPdfResponse.ok && iceSettlementPdfResponse.ok) {
            const settlementPdfBlob = await settlementPdfResponse.blob();
            const iceSettlementPdfBlob = await iceSettlementPdfResponse.blob();
            
            setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
            setIceSettlementPdfData(URL.createObjectURL(iceSettlementPdfBlob));
          } else {
            throw new Error('Failed to fetch one or both PDFs for discrepancy summary');
          }
        } else if (docId === 'nonpayables') {
          console.log('Fetching nonpayables documents...');
          const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_documents`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log('Received nonpayables document data:', data);

            const baseUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/`;
            const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
            const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

            console.log('Constructed Claim PDF URL:', claimPdfUrl);
            console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

            const claimBlob = await fetch(claimPdfUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
            });
            const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
            });

            const claimBlobUrl = URL.createObjectURL(claimBlob);
            const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);

            console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
            console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

            setClaimPdfData(claimBlobUrl);
            setPdfData(nonpayablesBlobUrl);

            // Fetch settlement PDF for nonpayables
            const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;
            console.log('Fetching settlement PDF from:', settlementPdfUrl);
            const settlementPdfResponse = await fetch(settlementPdfUrl);
            if (settlementPdfResponse.ok) {
              const settlementPdfBlob = await settlementPdfResponse.blob();
              setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
              console.log('Successfully loaded settlement PDF for nonpayables');
            } else {
              console.warn('Settlement PDF not found or failed to load for nonpayables');
              setSettlementPdfData(null);
            }
          } else {
            const errorText = await response.text();
            console.error('Failed to fetch documents:', errorText);
            setError('Failed to fetch documents');
          }
        } else {
          console.log('Fetching other document types...');
          const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
          const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;
          const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;

          console.log('Fetching fixed PDF from:', fixedPdfUrl);
          const fixedPdfResponse = await fetch(fixedPdfUrl);
          if (fixedPdfResponse.ok) {
            const fixedPdfBlob = await fixedPdfResponse.blob();
            setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
          } else {
            throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
          }

          console.log('Fetching other PDF from:', otherPdfUrl);
          const otherPdfResponse = await fetch(otherPdfUrl);
          if (otherPdfResponse.ok) {
            const otherPdfBlob = await otherPdfResponse.blob();
            setPdfData(URL.createObjectURL(otherPdfBlob));
          } else {
            throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
          }

          console.log('Fetching settlement PDF from:', settlementPdfUrl);
          const settlementPdfResponse = await fetch(settlementPdfUrl);
          if (settlementPdfResponse.ok) {
            const settlementPdfBlob = await settlementPdfResponse.blob();
            setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
          } else {
            console.warn('Settlement PDF not found or failed to load');
            setSettlementPdfData(null);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setError('Error fetching documents');
        setLoading(false);
      }
    };

    const fetchPolicyStatus = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
          setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
          setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
          setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
          setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
          console.log('Policy status fetched:', data);
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch policy status:', errorText);
          setError('Failed to fetch policy status');
        }
      } catch (error) {
        console.error('Error fetching policy status:', error);
        setError('Error fetching policy status');
      }
    };

    fetchResponseText();
    fetchDocuments();
    fetchPolicyStatus();
  }, [docId]);

  function onDocumentLoadSuccessLeft({ numPages }) {
    console.log('Loaded left document with number of pages:', numPages);
    setNumPagesLeft(numPages);
  }

  function onDocumentLoadSuccessRight({ numPages }) {
    console.log('Loaded right document with number of pages:', numPages);
    setNumPagesRight(numPages);
  }

  function onDocumentLoadError(error) {
    console.error('Error loading PDF document:', error);
    setPdfError('Failed to load PDF file. Please check the file format or try again later.');
  }

  const handleToggleDocument = () => {
    setIsDocumentType((prevState) => !prevState);
  };

  const handleConfirmDiscrepancy = async () => {
    if (actionToConfirm === 'overrule') {
        if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
            const discrepancyAmount = discrepancyAmounts[currentDiscrepancyIndex] || 0;
            const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;

            let requestBody = {
                ice_authorized_amount: newIceAuthorizedAmount
            };

            // **Calculate new totals before updating the state and request body**
            let newTotalPolicyWordingAmount;
            let newTotalNonpayablesAmount;
            let newTotalTariffDiscrepancyAmount;

            if (docId === 'policy_wording_document') {
                newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount - discrepancyAmount;
                requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
            } else if (docId === 'nonpayables') {
                newTotalNonpayablesAmount = totalNonpayablesAmount - discrepancyAmount;
                requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
            } else if (docId === 'tariff_document') {
                newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount - discrepancyAmount;
                requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
            }

            try {
                // Call the FastAPI endpoint to update the data
                const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data.message);

                    // Update state only after successful response from the server
                    setIceAuthorizedAmount(newIceAuthorizedAmount);

                    if (docId === 'policy_wording_document') {
                        setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
                    } else if (docId === 'nonpayables') {
                        setTotalNonpayablesAmount(newTotalNonpayablesAmount);
                    } else if (docId === 'tariff_document') {
                        setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
                    }

                    setOverruledDiscrepancies((prevState) => ({
                        ...prevState,
                        [currentDiscrepancyIndex]: true,
                    }));

                    setActionHistory((prevHistory) => [
                        ...prevHistory,
                        { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmount },
                    ]);
                } else {
                    console.error('Failed to update data:', await response.text());
                }
            } catch (error) {
                console.error('Error updating data:', error);
            }
        }
    }
    setIsConfirmBoxVisible(false);
    setActionToConfirm(null);
};

const handleOverruleDiscrepancy = () => {
  setActionToConfirm('overrule');
  setIsConfirmBoxVisible(true);
};


  const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
    console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
    setActionHistory((prevHistory) => [
      ...prevHistory,
      { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
    ]);
    setDiscrepancyAmounts((prevState) => ({
      ...prevState,
      [discrepancyIndex]: newDiscrepancyAmount,
    }));
    setCurrentDiscrepancyIndex(discrepancyIndex);
  };

  const revertLastAction = async () => {
    console.log('revertLastAction called');
    if (actionHistory.length === 0) {
        console.log('No actions to revert');
        return;
    }

    const lastAction = actionHistory[actionHistory.length - 1];
    console.log('Last action:', lastAction);

    if (lastAction.action === 'overrule') {
        const revertedAmount = iceAuthorizedAmount - lastAction.amount;
        let requestBody = {
            ice_authorized_amount: revertedAmount
        };

        // Declare variables before updating state and request body
        let newTotalPolicyWordingAmount;
        let newTotalNonpayablesAmount;
        let newTotalTariffDiscrepancyAmount;

        // Revert the specific document amount based on docId
        if (docId === 'policy_wording_document') {
            newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount + lastAction.amount;
            requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
        } else if (docId === 'nonpayables') {
            newTotalNonpayablesAmount = totalNonpayablesAmount + lastAction.amount;
            requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
        } else if (docId === 'tariff_document') {
            newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount + lastAction.amount;
            requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
        }

        try {
            // Call the FastAPI endpoint to update the data on the server
            const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);

                // Update state after successful response from the server
                setIceAuthorizedAmount(revertedAmount);

                if (docId === 'policy_wording_document') {
                    setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
                } else if (docId === 'nonpayables') {
                    setTotalNonpayablesAmount(newTotalNonpayablesAmount);
                } else if (docId === 'tariff_document') {
                    setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
                }

                // Remove the overruled discrepancy from state
                setOverruledDiscrepancies((prevState) => {
                    const newState = { ...prevState };
                    delete newState[lastAction.index];
                    return newState;
                });
            } else {
                console.error('Failed to update data:', await response.text());
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    } else if (lastAction.action === 'update') {
        const revertedAmount = lastAction.amount;
        const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

        try {
            const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    [discrepancyKey]: revertedAmount,
                }),
            });

            if (response.ok) {
                console.log('Discrepancy reverted successfully on the server.');
                setDiscrepancyAmounts((prevState) => ({
                    ...prevState,
                    [lastAction.index]: revertedAmount,
                }));
                setCurrentDiscrepancyIndex(lastAction.index);
            } else {
                const errorText = await response.text();
                console.error('Failed to revert discrepancy on the server:', errorText);
            }
        } catch (error) {
            console.error('Error reverting discrepancy:', error);
        }
    }

    // Remove the last action from the history
    setActionHistory((prevHistory) => prevHistory.slice(0, -1));
};


  const nameMappings = {
    policy_document: 'Enrollment',
    policy_wording_document: 'Policy Wording',
    stg_document: 'Standard Treatment Guidelines',
    tariff_document: 'Tariff',
    nonpayables: 'Non Payables',
    discrepancy_summary: 'Discrepancy Summary',
  };

  const toTitleCase = (str) => {
    if (!str) return '';
    return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
      if (txt.toLowerCase() === 'stg') {
        return 'STG';
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  if (loading) {
    console.log('Loading data...');
    return <div>Loading...</div>;
  }
  
  if (error) {
    console.error('Error encountered:', error);
    return <div>Error: {error}</div>;
  }
  
  if (docId === 'discrepancy_summary') {
    if (!settlementPdfData || !iceSettlementPdfData) {
      console.log(
        'Missing data for discrepancy_summary - settlementPdfData:',
        settlementPdfData,
        'iceSettlementPdfData:',
        iceSettlementPdfData
      );
      return <div>Loading...</div>;
    }
  } else if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
    console.log(
      'Missing data - responseText:',
      responseText,
      'pdfData:',
      pdfData,
      'claimPdfData:',
      claimPdfData,
      'settlementPdfData:',
      settlementPdfData
    );
    return <div>Loading...</div>;
  }
  

  const titleCaseDocId = toTitleCase(docId);

  console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);

  return (
    <div className="audit-details-page">
      <Header
        docId={docId}
        estEligibleAmount={estEligibleAmount}
        discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
        onOverruleDiscrepancy={handleOverruleDiscrepancy}
        onDiscrepancyAmountUpdate={handleDiscrepancyChange}
        overruledDiscrepancies={overruledDiscrepancies}
        currentDiscrepancyIndex={currentDiscrepancyIndex}
        iceAuthorizedAmount={iceAuthorizedAmount} // Pass ICE Authorized Amount to Header
      />

<div className="button-group">
    <div className="revert-button-container">
        <button onClick={revertLastAction} className="revert-button">
            Revert Last Action
        </button>
    </div>
    <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
        Overrule Deductions
    </button>
    <div className="confirm-discrepancy-btn">
        <button onClick={handleConfirmDiscrepancy}>Confirm Deductions</button>
        {isConfirmBoxVisible && (
            <div className="floating-confirm-box">
                <p>Are you sure you want to confirm this discrepancy?</p>
            </div>
        )}
    </div>
</div>


<div className="content">
  <div className="pdf-comparison">
    {docId === 'discrepancy_summary' ? (
      <>
        <div className="pdf-container">
          <h3>Settlement Document</h3>
          <div className="pdf-viewer">
            <Document file={settlementPdfData} onLoadSuccess={onDocumentLoadSuccessLeft} onLoadError={onDocumentLoadError}>
              {Array.from(new Array(numPagesLeft), (el, index) => (
                <Page key={`settlement_page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
        <div className="pdf-container">
          <h3>ICE Settlement Document</h3>
          <div className="pdf-viewer">
            <Document file={iceSettlementPdfData} onLoadSuccess={onDocumentLoadSuccessRight} onLoadError={onDocumentLoadError}>
              {Array.from(new Array(numPagesRight), (el, index) => (
                <Page key={`ice_settlement_page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="pdf-container">
          <h3>Claim Document</h3>
          <div className="pdf-viewer">
            <Document file={claimPdfData} onLoadSuccess={onDocumentLoadSuccessLeft} onLoadError={onDocumentLoadError}>
              {Array.from(new Array(numPagesLeft), (el, index) => (
                <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
        <div className="pdf-container">
            <h3>{titleCaseDocId}</h3>
            <div className="pdf-viewer">
              <Document
                file={pdfData}
                onLoadSuccess={onDocumentLoadSuccessRight}
                onLoadError={onDocumentLoadError}
              >
                {Array.from(new Array(numPagesRight), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
          </div>
        </div>
      </>
    )}
  </div>
</div>
</div>
  );
}

export default AuditDetailsPage;







// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { API_ENDPOINTS } from '../config';

// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0);

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           if (data.response_text && data.response_text[docId]) {
//             setResponseText(data.response_text[docId]);
//           } else {
//             setResponseText('No response text available.');
//           }
//         } else {
//           setError('Failed to fetch response text');
//         }
//       } catch (error) {
//         setError('Error fetching response text');
//       }
//     };

//     const fetchDocuments = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_documents`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           const baseUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/`;
//           const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
//           const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

//           const claimBlob = await fetch(claimPdfUrl).then((res) => res.blob());
//           const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => res.blob());

//           setClaimPdfData(URL.createObjectURL(claimBlob));
//           setPdfData(URL.createObjectURL(nonpayablesBlob));
//         } else {
//           setError('Failed to fetch documents');
//         }
//         setLoading(false);
//       } catch (error) {
//         setError('Error fetching documents');
//         setLoading(false);
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   const handleOverruleDiscrepancy = () => {
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = async () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         const discrepancyAmount = discrepancyAmounts[currentDiscrepancyIndex] || 0;
//         const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;

//         let requestBody = {
//           ice_authorized_amount: newIceAuthorizedAmount,
//         };

//         try {
//           const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(requestBody),
//           });

//           if (response.ok) {
//             const data = await response.json();
//             setIceAuthorizedAmount(newIceAuthorizedAmount);
//             setOverruledDiscrepancies((prevState) => ({
//               ...prevState,
//               [currentDiscrepancyIndex]: true,
//             }));

//             setActionHistory((prevHistory) => [
//               ...prevHistory,
//               { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmount },
//             ]);
//           } else {
//             console.error('Failed to update data:', await response.text());
//           }
//         } catch (error) {
//           console.error('Error updating data:', error);
//         }
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   const revertLastAction = async () => {
//     if (actionHistory.length === 0) return;

//     const lastAction = actionHistory[actionHistory.length - 1];

//     if (lastAction.action === 'overrule') {
//       const revertedAmount = iceAuthorizedAmount - lastAction.amount;

//       let requestBody = {
//         ice_authorized_amount: revertedAmount,
//       };

//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (response.ok) {
//           setIceAuthorizedAmount(revertedAmount);

//           setOverruledDiscrepancies((prevState) => {
//             const newState = { ...prevState };
//             delete newState[lastAction.index];
//             return newState;
//           });

//           setActionHistory((prevHistory) => prevHistory.slice(0, -1));
//         } else {
//           console.error('Failed to update data:', await response.text());
//         }
//       } catch (error) {
//         console.error('Error updating data:', error);
//       }
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   if (!responseText || !pdfData || !claimPdfData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="audit-details-page">
//       <Header
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={setDiscrepancyAmounts}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//         iceAuthorizedAmount={iceAuthorizedAmount}
//       />

//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">
//             Revert Last Action
//           </button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Deductions
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>Confirm Deductions</button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Claim Document</h3>
//             <div className="pdf-viewer">
//               <Document file={claimPdfData} onLoadSuccess={onDocumentLoadSuccessLeft} onLoadError={onDocumentLoadError}>
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`fixed_page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>{docId.replace(/_/g, ' ').toUpperCase()}</h3>
//             <div className="pdf-viewer">
//               <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccessRight} onLoadError={onDocumentLoadError}>
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AuditDetailsPage;
